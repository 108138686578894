import {
  IUser,
  IUserDetail,
  IUserAddRequest,
  IUserEditRequest,
  IUserApproveRequest
} from '../types/user';
import useApi from './index';

class UserApi {
  // ユーザ登録
  async addUsers(users: IUserAddRequest[]): Promise<void> {
    return useApi({
      method: 'post',
      url: '/users',
      data: users
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('post /users ', err);
        throw err;
      });
  }

  // ユーザ編集
  async editUser(userId: number, user: IUserEditRequest): Promise<void> {
    return useApi({
      method: 'patch',
      url: `/users/${userId}`,
      data: user
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('patch /users/' + userId, err);
        throw err;
      });
  }

  // ユーザ単一取得
  async getUser(userId: number): Promise<IUserDetail> {
    return useApi({
      method: 'get',
      url: `/users/${userId}`
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('get /users/' + userId, err);
        throw err;
      });
  }

  // ユーザ一覧取得
  async getUsers(keyword?: string): Promise<IUser[]> {
    return useApi({
      method: 'get',
      url: '/users',
      params: {
        keyword
      }
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('get /users ', err);
        throw err;
      });
  }

  // ユーザ承認
  async approveUsers(users: IUserApproveRequest): Promise<void> {
    return useApi({
      method: 'patch',
      url: '/users/approval',
      data: users
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('patch /users/approval', err);
        throw err;
      });
  }
}

export default new UserApi();
