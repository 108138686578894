import React, { useEffect, lazy } from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  RouteComponentProps
} from 'react-router-dom';

const LoginPage = lazy(() => import('../components/LoginPage'));
import ProjectProcessTemplate from '../components/ProjectProcessTemplate';
import { useAuth } from '../App';
import PageNotFound from '../PageNotFound';

export const Routes = withRouter(() => {
  return (
    <Switch>
      <AuthRoute
        exact
        path='/'
        children={(routeProps: RouteComponentProps) => (
          <LoginPage {...routeProps} />
        )}
      />
      <AuthRoute
        exact
        path='/login'
        children={(routeProps: RouteComponentProps) => (
          <LoginPage {...routeProps} />
        )}
      />
      <AuthRoute
        privateRoute
        path='/projects/:projectId/processes/:projectProcessId'
        children={(
          routeProps: RouteComponentProps<{
            projectId: string;
            projectProcessId: string;
          }>
        ) => <ProjectProcessTemplate {...routeProps} />}
      />
      <AuthRoute privateRoute children={() => <PageNotFound />} />
    </Switch>
  );
});

interface AuthRouteProps {
  children: any;
  privateRoute?: boolean;
  [x: string]: any;
}
const AuthRoute: React.FC<AuthRouteProps> = ({
  children,
  privateRoute,
  ...rest
}) => {
  const auth = useAuth();
  useEffect(() => {
    auth.fetchCurrentUser();
  }, []);
  if (auth.user.isFetched && !auth.user.user && privateRoute) {
    return (
      <Route
        {...rest}
        render={({ location }) => (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )}
      />
    );
  }

  return <Route {...rest} render={children} />;
};
