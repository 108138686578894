import { combineReducers, Action } from 'redux';
import user, { UserState } from './user';
import { UserAction } from '../actions/user';
import projects, { ProjectsState } from './project';
import { ProjectsAction } from '../actions/project';
import processes, { ProcessesState } from './process';
import { ProcessesAction } from '../actions/process';

export default combineReducers({
  user,
  projects,
  processes
});

export type ReduxState = {
  user: UserState;
  projects: ProjectsState;
  processes: ProcessesState;
};

export type ReduxAction =
  | UserAction
  | ProjectsAction
  | ProcessesAction
  | Action;
