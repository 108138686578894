export const getErrMsg = (
  code: string,
  row?: number,
  validationMessage?: string
): string => {
  const error = errors.find(err => err.code == code);
  return (
    ((error?.useRow == true && row?.toString()) || '') +
    error?.baseText +
    ((error?.useValidation == true && validationMessage) || '') +
    '[' +
    code +
    ']'
  );
};

type IError = {
  code: string;
  baseText: string;
  useRow?: boolean;
  useValidation?: boolean;
};

const errors: IError[] = [
  { code: 'U2_NETWORK_S-00-00_01', baseText: '再ログインが必要です。' },
  {
    code: 'U1_FORM_S-00-00_10',
    baseText: '郵便番号に該当する住所が見つかりません。'
  },
  { code: 'U1_FORM_S-01-01_01', baseText: '検索結果が0件です。' },
  { code: 'U2_NETWORK_S-01-01_10', baseText: '案件情報取得に失敗しました。' },
  { code: 'U2_NETWORK_S-01-01_11', baseText: '案件一覧の取得に失敗しました。' },
  {
    code: 'U2_NETWORK_S-01-01_12',
    baseText: '指定された案件情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-01-03_11', baseText: '大工程情報取得に失敗しました。' },
  { code: 'U2_EVENT_S-05-01_01', baseText: '未撮影の撮影枠は削除できません。' },
  {
    code: 'U2_EVENT_S-05-01_02',
    baseText: '工程と紐づかない写真のため、写真帳出力できません。'
  },
  { code: 'U2_NETWORK_S-05-01_10', baseText: '写真一覧の取得に失敗しました。' },
  { code: 'U2_NETWORK_S-05-01_11', baseText: '写真削除に失敗しました。' },
  {
    code: 'U2_NETWORK_S-05-01_12',
    baseText: '写真帳出力状態の変更に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-05-02_01',
    baseText: '写真詳細情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-06-01_01', baseText: '図面情報の取得に失敗しました。' },
  { code: 'U2_NETWORK_S-06-01_02', baseText: '案件情報の取得に失敗しました。' },
  { code: 'U2_NETWORK_S-06-01_03', baseText: 'ピン情報の取得に失敗しました。' },
  {
    code: 'U2_NETWORK_S-06-01_04',
    baseText: '図面・ピン情報の更新に失敗しました。'
  },
  { code: 'U2_NETWORK_S-06-01_05', baseText: '図面情報の取得に失敗しました。' },
  { code: 'U2_EVENT_S-08-02_01', baseText: '追加した作業のみ編集ができます。' },
  {
    code: 'U1_FORM_S-08-02_10',
    baseText: '全ての作業に状況（前、中、後）を１つ以上設定してください。'
  },
  {
    code: 'U1_FORM_S-08-02_11',
    baseText: '全ての作業に対象機を１つ以上設定してください。'
  },
  {
    code: 'U1_FORM_S-08-02_12',
    baseText: '全ての工程に作業を１つ以上設定してください。'
  },
  { code: 'U2_NETWORK_S-08-02_20', baseText: '工程情報の取得に失敗しました。' },
  { code: 'U2_NETWORK_S-08-02_21', baseText: '工程登録に失敗しました。' },
  { code: 'U2_EVENT_S-09-02_01', baseText: '1行に4つ以上配置できません。' },
  { code: 'U2_NETWORK_S-09-02_10', baseText: '工程情報の取得に失敗しました。' },
  {
    code: 'U2_NETWORK_S-09-02_11',
    baseText: '写真帳表示項目の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-09-02_12',
    baseText: '写真帳出力リクエストに失敗しました。'
  },
  { code: 'U1_FORM_S-14-01_01', baseText: '検索結果が0件です。' },
  {
    code: 'U2_EVENT_S-14-01_01',
    baseText: '承認可能なのはシステム管理者かユーザ管理者です。'
  },
  {
    code: 'U2_NETWORK_S-14-01_10',
    baseText: 'ユーザ一覧の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-01_11', baseText: '一括承認に失敗しました。' },
  { code: 'U2_NETWORK_S-14-01_12', baseText: '承認に失敗しました。' },
  { code: 'U2_NETWORK_S-14-02_01', baseText: '権限一覧の取得に失敗しました。' },
  { code: 'U2_NETWORK_S-14-02_02', baseText: 'ユーザ登録に失敗しました。' },
  { code: 'U2_NETWORK_S-14-03_01', baseText: 'ユーザ編集に失敗しました。' },
  { code: 'U1_FORM_S-14-04_01', baseText: '検索結果が0件です。' },
  { code: 'U2_NETWORK_S-14-04_10', baseText: '組織一覧の取得に失敗しました。' },
  {
    code: 'U1_FORM_S-14-05_01',
    baseText: 'この組織名は登録済です。重複しないよう設定してください。'
  },
  { code: 'U2_NETWORK_S-14-05_10', baseText: '組織登録に失敗しました。' },
  {
    code: 'U1_FORM_S-14-06_01',
    baseText: 'この組織名は登録済です。重複しないよう設定してください。'
  },
  {
    code: 'U2_NETWORK_S-14-06_10',
    baseText: '組織詳細情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-06_11', baseText: '組織編集に失敗しました。' },
  { code: 'U1_FORM_S-14-07_01', baseText: '検索結果が0件です。' },
  { code: 'U2_NETWORK_S-14-07_10', baseText: '案件一覧の取得に失敗しました。' },
  {
    code: 'U2_NETWORK_S-14-08_01',
    baseText: '大工程一覧の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-08_02', baseText: '案件登録に失敗しました。' },
  {
    code: 'U2_NETWORK_S-14-09_01',
    baseText: '主担当者情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09_02',
    baseText: '案件詳細情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09_03',
    baseText: '案件詳細情報の再読み込みに失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09_04',
    baseText: 'ユーザ情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09_05', baseText: '案件編集に失敗しました。' },
  {
    code: 'U2_NETWORK_S-14-09-01_01',
    baseText: '物件情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09-01_02', baseText: '物件設定に失敗しました。' },
  {
    code: 'U2_NETWORK_S-14-09-02_01',
    baseText: 'フロア情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-02_02',
    baseText: 'フロア/図面設定に失敗しました。'
  },
  {
    code: 'U1_FORM_S-14-09-02_11',
    baseText:
      'フロアが未入力です。プルダウンを選択または特殊フロアを入力してください。'
  },
  {
    code: 'U1_FILE_S-14-09-02_12',
    baseText: '図面ファイルサイズは6MB以下にしてください。'
  },
  {
    code: 'U1_FILE_S-14-09-02_13',
    baseText: '図面ファイル名は拡張子を含め100字以下にしてください。'
  },
  {
    code: 'U1_FILE_S-14-09-02_14',
    baseText:
      'PDFファイルまたは画像ファイル（png、jpeg、jpg）を選択してください。'
  },
  { code: 'U1_FORM_S-14-09-02_15', baseText: 'フロアが重複しています。' },
  {
    code: 'U1_FILE_S-14-09-02_16',
    baseText: 'PDFのページ数は1ページにしてください。'
  },
  { code: 'U1_FORM_S-14-09-02_15', baseText: 'フロアが重複しています。' },
  {
    code: 'U2_NETWORK_S-14-09-03_01',
    baseText: '機器情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09-03_02', baseText: '機器設定に失敗しました。' },
  {
    code: 'U1_FILE_S-14-09-03_00',
    baseText:
      'エクセル読み込み時エラーです。未入力項目がないか、不必要なセルを編集していないか確認してください。'
  },
  {
    code: 'U1_FORM_S-14-09-03_10',
    baseText: '行目の室外機機種名、室内機機種名：一方を入力してください。',
    useRow: true
  },
  {
    code: 'U1_FORM_S-14-09-03_11',
    baseText:
      '機器IDが重複しています。「Excelファイル書出」後の機器IDは変更しないでください。また、追加する機器の機器IDは空欄にしてください。'
  },
  {
    code: 'U1_FORM_S-14-09-03_12',
    baseText: '記号が重複しています。重複しないように設定してください。'
  },
  {
    code: 'U1_FORM_S-14-09-03_13',
    baseText:
      '未登録のフロアが入力されています。案件編集画面の「フロア/図面設定」を確認してください。'
  },
  {
    code: 'U1_FORM_S-14-09-03_14',
    baseText: '行目の部屋名：場所名を入力する場合は必須入力です。',
    useRow: true
  },
  {
    code: 'U1_FORM_S-14-09-03_20',
    baseText: '行目の記号：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_21',
    baseText: '行目の室外機機種名：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_22',
    baseText: '行目の室内機機種名：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_23',
    baseText: '行目の系統：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_24',
    baseText: '行目の部屋名：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_25',
    baseText: '行目の場所名：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FORM_S-14-09-03_26',
    baseText: '行目の記号：必須入力です。',
    useRow: true
  },
  {
    code: 'U1_FORM_S-14-09-03_27',
    baseText: '行目の系統：必須入力です。',
    useRow: true
  },
  {
    code: 'U1_FORM_S-14-09-03_28',
    baseText: '行目のフロア名：必須入力です。',
    useRow: true
  },
  {
    code: 'U2_NETWORK_S-14-09-04_01',
    baseText: '立会人情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09-04_02', baseText: '立会人設定に失敗しました。' },
  {
    code: 'U2_NETWORK_S-14-09-05_01',
    baseText: '大工程情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-05_02',
    baseText: '工程テンプレートの取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-05_03',
    baseText: '工程情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09-05_04', baseText: '工程設定に失敗しました。' },
  {
    code: 'U1_SELECT_S-14-09-05_10',
    baseText: '全ての作業に状況（前、中、後）を１つ以上設定してください。'
  },
  {
    code: 'U1_SELECT_S-14-09-05_11',
    baseText: '全ての作業に対象機を１つ以上設定してください。'
  },
  {
    code: 'U1_FORM_S-14-09-05_12',
    baseText: '全ての工程に作業を１つ以上設定してください。'
  },
  {
    code: 'U2_NETWORK_S-14-09-06_01',
    baseText: '黒板情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-06_02',
    baseText: '黒板設定に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-07_01',
    baseText: 'ユーザ情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-07_02',
    baseText: '担当者情報の取得に失敗しました。'
  },
  {
    code: 'U2_NETWORK_S-14-09-07_03',
    baseText: 'ユーザ情報の取得に失敗しました。'
  },
  { code: 'U2_NETWORK_S-14-09-07_04', baseText: '担当者設定に失敗しました。' },
  {
    code: 'U1_FILE_S-14-10_00',
    baseText:
      'エクセル読み込み時エラーです。未入力項目がないか、不必要なセルを編集していないか確認してください。'
  },
  { code: 'U1_FILE_S-14-10_10', baseText: 'ユーザ情報が入力されていません。' },
  {
    code: 'U1_FILE_S-14-10_11',
    baseText:
      '一度に読み込めるユーザは100名迄です。複数ファイルに分けてください。'
  },
  {
    code: 'U1_FILE_S-14-10_12',
    baseText: '行目のメールアドレス：他の行と重複しています。',
    useRow: true
  },
  {
    code: 'U1_FILE_S-14-10_13',
    baseText: '行目の電話番号：他の行と重複しています。',
    useRow: true
  },
  {
    code: 'U1_FILE_S-14-10_20',
    baseText: '行目のユーザ名：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FILE_S-14-10_21',
    baseText: '行目のメールアドレス：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FILE_S-14-10_22',
    baseText: '行目の電話番号：',
    useRow: true,
    useValidation: true
  },
  {
    code: 'U1_FILE_S-14-10_23',
    baseText: '行目の組織：値が不正です。',
    useRow: true
  },
  {
    code: 'U1_FILE_S-14-10_24',
    baseText: '行目の権限：値が不正です。',
    useRow: true
  },
  { code: 'U2_NETWORK_S-14-10_30', baseText: 'ユーザ一括登録に失敗しました。' }
];
