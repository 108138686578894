import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import FlagIcon from '@material-ui/icons/Flag';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import DescriptionIcon from '@material-ui/icons/Description';

const SideLinkWrapper = styled.div<{ isSelected: boolean }>`
  padding: 6px;
  > a {
    ${props =>
      props.isSelected &&
      css`
        background: rgba(0, 151, 224, 0.08);
        svg,
        span {
          color: #0097e0;
        }
      `}
  }
`;

interface Props {
  classes: any;
  baseUrl: string;
  isSelectedPath(path: string): boolean;
  isDisabled: boolean;
}

export const ProjectProcessSideBar: React.FC<Props> = props => {
  const { classes, baseUrl, isSelectedPath, isDisabled } = props;
  return (
    <Drawer
      className={classes.drawer}
      variant='permanent'
      classes={{
        paper: classes.drawerPaper
      }}
      anchor='left'
    >
      <div className={classes.toolbar} style={{ overflow: 'hidden' }}>
        <Typography
          variant='h6'
          style={{ position: 'relative', top: '16px', left: '15px' }}
        >
          D-site
        </Typography>
      </div>
      <Divider />
      <List style={{ padding: 0 }}>
        <SideLinkWrapper isSelected={isSelectedPath('/photos')}>
          <ListItem
            button
            disabled={isDisabled}
            component={Link}
            to={`${baseUrl}/photos`}
          >
            <ListItemIcon>
              <ViewComfyIcon />
            </ListItemIcon>
            <ListItemText className='photosMenu'>写真一覧</ListItemText>
          </ListItem>
        </SideLinkWrapper>
        <SideLinkWrapper isSelected={isSelectedPath('/drawing')}>
          <ListItem
            button
            disabled={isDisabled}
            component={Link}
            to={`${baseUrl}/drawing`}
          >
            <ListItemIcon>
              <BeenhereIcon />
            </ListItemIcon>
            <ListItemText className='drawingMenu'>図面表示</ListItemText>
          </ListItem>
        </SideLinkWrapper>
        <SideLinkWrapper isSelected={isSelectedPath('/output')}>
          <ListItem
            button
            disabled={isDisabled}
            component={Link}
            to={`${baseUrl}/output`}
          >
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText className='outputMenu'>写真帳出力</ListItemText>
          </ListItem>
        </SideLinkWrapper>
        <SideLinkWrapper isSelected={isSelectedPath('/operations')}>
          <ListItem
            button
            disabled={isDisabled}
            component={Link}
            to={`${baseUrl}/operations`}
          >
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText className='operationsMenu'>工程登録</ListItemText>
          </ListItem>
        </SideLinkWrapper>
        <p style={{ left: 24, bottom: 8, position: 'fixed', color: '#999999' }}>
          {'Revision: ' + GIT_COMMIT_HASH}
        </p>
      </List>
      <Divider />
    </Drawer>
  );
};
