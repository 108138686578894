import React from 'react';
import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';

const IndicatorWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  > div {
    position: relative;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
  }
`;

const LoadingIndicator: React.FC = () => (
  <IndicatorWrapper>
    <CircularProgress size={70} style={{ position: 'fixed' }} />
  </IndicatorWrapper>
);

export default LoadingIndicator;
