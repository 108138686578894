export const prefectures = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県'
];

export function contentsName(content: string): string {
  if (content === 'operation') {
    return '工程';
  }
  return '作業';
}

/**
 * URLクエリをオブジェクト形式で返す
 * @param searchStr - string 型
 */
export function getSearchObj(
  searchStr: string
): { [key: string]: string | string[] } {
  if (!searchStr) return {};
  const obj: { [key: string]: string | string[] } = {};
  decodeURI(searchStr)
    .substr(1)
    .split('&')
    .forEach(el => {
      const key = el.split('=')[0];
      const value = el.split('=')[1];
      const existingValue = obj[key];
      // キーが重複している場合、配列形式に格納
      if (existingValue && Array.isArray(existingValue)) {
        obj[key] = [...existingValue, value];
      } else if (existingValue && !Array.isArray(existingValue)) {
        obj[key] = [existingValue, value];
      } else {
        obj[key] = value;
      }
    });
  return obj;
}

/**
 * オブジェクトをURLクエリ形式で返す
 * @param searchObj - object 型
 */
export function mergeSearchQuery(searchObj: { [key: string]: any }): string {
  const searchQueryArray: string[] = [];
  Object.keys(searchObj).forEach((key, index) => {
    if (Array.isArray(searchObj[key])) {
      searchObj[key].forEach((el: any, li: number) => {
        const addedQuery = `${index !== 0 || li !== 0 ? '&' : ''}${key}=${el}`;
        searchQueryArray.push(addedQuery);
      });
    } else {
      const addedQuery = `${index !== 0 ? '&' : ''}${key}=${searchObj[key]}`;
      searchQueryArray.push(addedQuery);
    }
  });
  return searchQueryArray.join('');
}

// 文字列を半角数字に変換
export const replaceHalfWidthDigit = (str: string) =>
  str.replace(/[^0-9|^０-９]/g, '').replace(/[０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });

// 機器名を半角英数ハイフンに変換
export const replaceEquipmentName = (str: string) =>
  str
    .replace(/[－|―|‐|－|ー|─|━]/g, '-')
    .replace(/[ａ-ｚＡ-Ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
    });
