import useApi from './index';

import { IProjectToAdd } from '../types/requests/project';
import { IProject, IProjectDetail } from '../types/project';

class ProjectApi {
  async getProjectsList({
    managementFlag,
    nextToken,
    limit,
    keyword
  }: {
    managementFlag: boolean;
    nextToken?: string;
    limit?: number;
    keyword?: string;
  }): Promise<{
    items: IProject[];
    nextToken: string;
  }> {
    return useApi({
      method: 'get',
      url: '/projects',
      params: {
        managementFlag,
        nextToken,
        limit,
        keyword
      }
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }

  async addProject(project: IProjectToAdd): Promise<IProject[]> {
    return useApi({
      method: 'post',
      url: '/projects',
      data: project
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }

  async updateProject({
    projectId,
    projectName,
    mainWorkerUserId,
    organizationId
  }: {
    projectId: number;
    projectName?: string;
    mainWorkerUserId?: number;
    organizationId?: number;
  }): Promise<null> {
    return useApi({
      method: 'patch',
      url: `/projects/${projectId}`,
      data: {
        projectName,
        mainWorkerUserId,
        organizationId
      }
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }

  async getProjectDetail({
    projectId,
    managementFlag
  }: {
    projectId: number;
    managementFlag: boolean;
  }): Promise<IProjectDetail> {
    return useApi({
      method: 'get',
      url: `/projects/${projectId}`,
      params: { managementFlag }
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }
}

export default new ProjectApi();
