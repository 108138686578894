import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiBaseUrl } from '@config/apiBaseUrl';
import Amplify from '@common/utils/amplify';

const useApi = async (config: AxiosRequestConfig): Promise<AxiosResponse> => {
  const jwtToken = await Amplify.getCurrentIdToken().catch(err => {
    Amplify.signOut();
    window.location.href = '/login';
    throw err;
  });

  return axios({
    method: config.method || 'get',
    baseURL: apiBaseUrl,
    url: config.url,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: jwtToken
    },
    data: config.data,
    params: mergeParams(config.params)
  }).catch(err => {
    if (err?.response?.status === 401) {
      Amplify.signOut();
      window.location.href = '/login';
    }
    throw err;
  });
};

/**
 * オブジェクトをURLパラメータ形式で返す
 * @param argParams - object 型
 */
const mergeParams = (argParams: { [key: string]: any }) => {
  // 複数の同一クエリを指定しうるので、 URLSearchParams を用いて強制的に指定
  const params = new URLSearchParams();
  params.append('timestamp', new Date().getTime().toString());
  Object.keys(argParams || {}).forEach((key: string) => {
    const param = argParams[key];
    if (param === null || param === undefined) {
      return;
    }

    // 同一クエリ要素は配列に格納しているため、各要素ごとに処理
    if (Array.isArray(param)) {
      (param || []).forEach((el: string) => {
        params.append(key, el);
      });
      return;
    }
    params.append(key, param);
  });
  return params;
};

export default useApi;
