import { IUserDetail } from '@common/types/user';

export const FETCH_USER = 'FETCH_USER';
export type FetchUser = {
  type: typeof FETCH_USER;
};

export const fetchUser = (): FetchUser => ({
  type: FETCH_USER
});

export const SET_USER = 'SET_USER' as const;
export type SetUser = {
  type: typeof SET_USER;
  payload: IUserDetail | null;
};

export const setUser = (user: IUserDetail | null): SetUser => ({
  type: SET_USER,
  payload: user
});

export const CLEAR_USER = 'CLEAR_USER' as const;
export type ClearUser = {
  type: typeof CLEAR_USER;
};

export const clearUser = (): ClearUser => ({
  type: CLEAR_USER
});

export type UserAction = FetchUser | SetUser | ClearUser;
