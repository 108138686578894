import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../reducers';
import { logout } from '../thunks/user';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Popper from '@material-ui/core/Popper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import settingIconSrc from '../../public/settings-outline.svg';
import pathIconSrc from '../../public/path.svg';
import accountIconSrc from '../../public/account_circle-24px.svg';

import { IProject, IProjectDetail } from '@common/types/project';
import { IProjectProcess } from '@common/types/process';

interface Props {
  classes: any;
  setOpenUserMenu(open: boolean): void;
  setOpenProjectMenu(open: boolean): void;
  setOpenProcessMenu(open: boolean): void;
  openUserMenu: boolean;
  openProcessMenu: boolean;
  openProjectMenu: boolean;
  currentProject?: IProject | IProjectDetail;
  currentProcess?: IProjectProcess;
  projectId: number;
  isDisabled: boolean;
  setProjectAndProcess(
    projectId: number | null,
    projectProcessId: number | null
  ): void;
  handleListKeyDown(event: React.KeyboardEvent): void;
}
type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export const ProjectProcessHeader: React.FC<Props & PropsFromRedux> = props => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const userElementRef = useRef<HTMLDivElement>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const {
    classes,
    setOpenUserMenu,
    setOpenProjectMenu,
    setOpenProcessMenu,
    openUserMenu,
    openProcessMenu,
    openProjectMenu,
    currentProject,
    currentProcess,
    isDisabled,
    handleListKeyDown
  } = props;

  const handleLogout = () => {
    setSubmitting(true);
    props.logout();
    setSubmitting(false);
  };

  useEffect(() => {
    document.querySelectorAll('.Ellipsis').forEach(function (elem) {
      if (
        elem.textContent &&
        parseFloat(window.getComputedStyle(elem).width) < elem.scrollWidth - 1
      ) {
        elem.setAttribute('title', elem.textContent);
      } else {
        elem.setAttribute('title', '');
      }
    });

    document.querySelectorAll('.Ellipsis20').forEach(function (elem) {
      if (
        elem.textContent &&
        parseFloat(window.getComputedStyle(elem).width) < elem.scrollWidth - 20
      ) {
        elem.setAttribute('title', elem.textContent);
      } else {
        elem.setAttribute('title', '');
      }
    });
  }, [currentProject?.projectName, currentProcess?.processName]);

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Grid container>
          <Grid item md={4} style={{ position: 'relative' }} zeroMinWidth>
            <Typography variant='h6' style={{ color: '#CCCCCC' }} noWrap>
              案件
            </Typography>
            <Typography
              variant='h6'
              style={{ maxWidth: 'calc(100% - 20px)' }}
              noWrap
              className='Ellipsis20'
            >
              {currentProject?.projectName}
            </Typography>
            <button
              disabled={isDisabled}
              onClick={() => {
                setOpenProjectMenu(!openProjectMenu);
              }}
              style={{
                position: 'absolute',
                cursor: 'pointer',
                background: 'transparent',
                border: 'none',
                right: 0,
                width: 30,
                top: 20,
                height: 30,
                justifyContent: 'center'
              }}
            >
              <img src={settingIconSrc} style={{ width: 20 }} />
            </button>
          </Grid>

          <Divider
            orientation='vertical'
            style={{ backgroundColor: '#666666', margin: 7 }}
            flexItem
          />
          <Grid
            item
            md
            ref={anchorRef}
            style={{ position: 'relative' }}
            zeroMinWidth
          >
            <Typography variant='h6' style={{ color: '#CCCCCC' }} noWrap>
              大工程
            </Typography>
            <Typography
              variant='h6'
              style={{ maxWidth: 'calc(100% - 20px)' }}
              noWrap
              className='Ellipsis20'
            >
              {(props.processes.processes.length > 0 &&
                currentProcess?.processName) ||
                'なし'}
            </Typography>
            {props.processes.processes.length > 0 && (
              <button
                onClick={() => {
                  setOpenProcessMenu(!openProcessMenu);
                }}
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  background: 'transparent',
                  border: 'none',
                  right: 0,
                  width: 30,
                  top: 20,
                  height: 30,
                  justifyContent: 'center'
                }}
              >
                <img
                  src={pathIconSrc}
                  style={{
                    width: 20
                  }}
                />
              </button>
            )}
            <Popper
              open={openProcessMenu}
              anchorEl={anchorRef.current}
              placement='bottom-end'
              role={undefined}
              transition
              disablePortal
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenProcessMenu(false);
                  }}
                >
                  <MenuList onKeyDown={handleListKeyDown}>
                    {(props.processes.processes || []).map(
                      (process: IProjectProcess, index: number) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            setOpenProcessMenu(false);
                            props.setProjectAndProcess(
                              process.projectId,
                              process.projectProcessId
                            );
                          }}
                        >
                          {process.processName}
                        </MenuItem>
                      )
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </Grid>
          <Divider
            orientation='vertical'
            style={{ backgroundColor: '#666666', margin: 7 }}
            flexItem
          />
          <Grid item md zeroMinWidth>
            <Typography variant='h6' style={{ color: '#CCCCCC' }} noWrap>
              物件
            </Typography>
            <Typography variant='h6' noWrap className='Ellipsis'>
              {currentProject?.buildingName}
            </Typography>
          </Grid>
          <Grid
            item
            md
            ref={userElementRef}
            onClick={() => setOpenUserMenu(!openUserMenu)}
            style={{ backgroundColor: '#000000', cursor: 'pointer' }}
            zeroMinWidth
          >
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  fontSize: '16',
                  height: 40,
                  maxWidth: 'calc(100% - 60px)',
                  margin: 'auto'
                }}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  className='Ellipsis'
                >
                  {props.user.user?.mailAddress}
                </div>
                <div
                  style={{
                    textAlign: 'right',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  className='Ellipsis'
                >
                  {props.user.user?.userName}
                </div>
              </div>
              <img
                src={accountIconSrc}
                style={{
                  justifyContent: 'right'
                }}
              />
            </div>
            <Popper
              open={openUserMenu}
              anchorEl={userElementRef.current}
              placement='bottom-end'
              role={undefined}
              transition
              disablePortal
            >
              <ClickAwayListener
                onClickAway={() => {
                  setOpenUserMenu(false);
                }}
              >
                <Paper>
                  <MenuList>
                    <MenuItem
                      style={{ width: userElementRef.current?.clientWidth }}
                      disabled={submitting}
                      onClick={handleLogout}
                    >
                      ログアウト
                    </MenuItem>
                  </MenuList>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  projects: state.projects,
  processes: state.processes
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectProcessHeader);
