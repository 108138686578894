import useApi from './index';

import { IProcess, IProjectProcess } from '../types/process';

class ProcessAPI {
  async getProcessesList({
    projectId
  }: {
    projectId: number;
  }): Promise<IProjectProcess[]> {
    return useApi({
      method: 'get',
      url: `/projects/${projectId}/processes`
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }

  async getProcessesMasterData(): Promise<IProcess[]> {
    return useApi({
      method: 'get',
      url: '/processes'
    })
      .then(({ data }) => data)
      .catch(err => {
        console.log('err', err);
        throw err;
      });
  }
}

export default new ProcessAPI();
