import { AppThunk, AppThunkAsync } from '.';
import {
  fetchProjects,
  setProjects,
  setProjectsError,
  addProject
} from '../actions/project';
import api from '@common/apis/project';

export const fetchAndSetProjects = (): AppThunk => async dispatch => {
  dispatch(fetchProjects());

  api
    .getProjectsList({
      managementFlag: false,
      limit: 100
    })
    .then(res => {
      dispatch(setProjects(res.items));
    })
    .catch(() => {
      dispatch(setProjectsError());
    });
};

// 案件一覧で取得上限により取得できなかった案件用
export const fetchAddProject = (
  projectId: number
): AppThunkAsync => async dispatch => {
  dispatch(fetchProjects());

  return await api
    .getProjectDetail({
      projectId: projectId,
      managementFlag: false
    })
    .then(projectDetail => {
      dispatch(addProject(projectDetail));
    })
    .catch(err => {
      dispatch(addProject(null));
      throw err;
    });
};
