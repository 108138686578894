import { IProject, IProjectDetail } from '@common/types/project';

export const FETCH_PROJECTS = 'FETCH_PROJECTS' as const;
export type FetchProjects = {
  type: typeof FETCH_PROJECTS;
};

export const fetchProjects = (): FetchProjects => ({
  type: FETCH_PROJECTS
});

export const SET_PROJECTS = 'SET_PROJECTS' as const;
export type SetProjects = {
  type: typeof SET_PROJECTS;
  payload: Array<IProject | IProjectDetail>;
};

export const setProjects = (
  projects: Array<IProject | IProjectDetail>
): SetProjects => ({
  type: SET_PROJECTS,
  payload: projects
});
export const ADD_PROJECT = 'ADD_PROJECT' as const;
export type AddProject = {
  type: typeof ADD_PROJECT;
  payload: IProject | IProjectDetail | null;
};
export const addProject = (
  project: IProject | IProjectDetail | null
): AddProject => ({
  type: ADD_PROJECT,
  payload: project
});

export const SET_PROJECTS_ERROR = 'SET_PROJECTS_ERROR' as const;
export type SetProjectsError = {
  type: typeof SET_PROJECTS_ERROR;
};
export const setProjectsError = (): SetProjectsError => ({
  type: SET_PROJECTS_ERROR
});

export const CLEAR_PROJECTS = 'CLEAR_PROJECTS' as const;
export type ClearProjects = {
  type: typeof CLEAR_PROJECTS;
};

export const clearProjects = (): ClearProjects => ({
  type: CLEAR_PROJECTS
});

export type ProjectsAction =
  | FetchProjects
  | AddProject
  | SetProjects
  | ClearProjects
  | SetProjectsError;
