import React, { useState } from 'react';
import { logout } from './thunks/user';
import { Action, bindActionCreators } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { connect } from 'react-redux';
import { ReduxState } from './reducers';
import Button from '@material-ui/core/Button';

type PropsFromRedux = ReturnType<typeof mapDispatchToProps>;
const SystemError: React.FC<PropsFromRedux> = props => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setSubmitting(true);
    props.logout();
  };

  return (
    <div style={{ marginTop: '24px', marginLeft: '24px' }}>
      <div>システムエラーが発生しました。</div>
      <Button
        size='large'
        disabled={submitting}
        onClick={handleSubmit}
        style={{ marginTop: '24px' }}
      >
        ログアウト
      </Button>
    </div>
  );
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, void, Action>
) =>
  bindActionCreators(
    {
      logout
    },
    dispatch
  );
const connector = connect(null, mapDispatchToProps);

export default connector(SystemError);
