import { Reducer } from 'redux';
import { IProjectProcess } from '@common/types/process';
import {
  FETCH_PROCESSES,
  SET_PROCESSES,
  SET_PROCESSES_ERROR,
  CLEAR_PROCESSES,
  ProcessesAction
} from '../actions/process';

export type ProcessesState = {
  isFetching: boolean;
  isFetched: boolean;
  processes: IProjectProcess[];
  error: boolean;
};

export const initialState: ProcessesState = {
  isFetching: false,
  isFetched: false,
  error: false,
  processes: []
};

const processReducer: Reducer<ProcessesState, ProcessesAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_PROCESSES: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        error: false
      };
    }

    case SET_PROCESSES: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: false,
        processes: action.payload
      };
    }
    case SET_PROCESSES_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: true,
        processes: []
      };
    }

    case CLEAR_PROCESSES: {
      return initialState;
    }

    default:
      return state;
  }
};

export default processReducer;
