import React, { useState } from 'react';
import Button from '@material-ui/core/Button';

const PageNotFound: React.FC = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = () => {
    setSubmitting(true);
    window.location.href = '/projects/redirect/processes/_/photos';
    setSubmitting(false);
  };

  return (
    <div style={{ marginTop: '24px', marginLeft: '24px' }}>
      <div>{'ページが見つかりません'}</div>
      <Button
        size='large'
        disabled={submitting}
        onClick={handleSubmit}
        style={{ marginTop: '24px' }}
      >
        写真一覧画面に移動
      </Button>
    </div>
  );
};

export default PageNotFound;
