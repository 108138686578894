import validator from 'validator';
import { replaceHalfWidthDigit } from '@common/utils/index';

interface IValidationRes {
  // 入力欄に反映するかどうか
  acceptable: boolean;
  // エラーメッセージ
  err?: string;
}

export const validateEmail = (
  mailAddress: string | undefined,
  comparedMailAddress?: string | undefined
): IValidationRes => {
  const trimmedMailAddress = mailAddress?.trim();
  if (!trimmedMailAddress?.match(/^[!-~]*$/)) {
    return {
      acceptable: true,
      err: '半角英数記号で入力してください。'
    };
  }
  if (!trimmedMailAddress?.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (trimmedMailAddress.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  const isDocomo = !!trimmedMailAddress.match(/@docomo.ne.jp$/);
  const isEzweb = !!trimmedMailAddress.match(/@ezweb.ne.jp$/);
  if (isDocomo || isEzweb) {
    const localPart = trimmedMailAddress.split('@')[0];
    if (!localPart.match(/^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]*$/)) {
      return {
        acceptable: true,
        err: '形式が正しくありません。'
      };
    }
  } else {
    if (!validator.isEmail(trimmedMailAddress)) {
      return {
        acceptable: true,
        err: '形式が正しくありません。'
      };
    }
  }
  if (comparedMailAddress && trimmedMailAddress !== comparedMailAddress) {
    return {
      acceptable: true,
      err: '確認用メールアドレスが一致しません。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateLoginPassword = (password: string): IValidationRes => {
  if (!password.match(/^[!-~\s]*$/)) {
    return {
      acceptable: true,
      err: '半角英数記号で入力してください。'
    };
  }
  if (!password?.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (password.length > 256) {
    return {
      acceptable: true,
      err: '256字以下で入力してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validatePassword = (
  password: string,
  comparedPassword?: string
): IValidationRes => {
  if (
    !password.match(
      /^[a-zA-Z0-9=\+\-\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/\\,><':;\|_~`]*$/
    )
  ) {
    return {
      acceptable: true,
      err: '半角英数記号で入力してください。'
    };
  }
  if (!password.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (!!password.length && password.length < 8) {
    return {
      acceptable: true,
      err: '8字以上で入力してください。'
    };
  }
  if (password.length > 256) {
    return {
      acceptable: true,
      err: '256字以下で入力してください。'
    };
  }
  if (
    !password.match(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[a-zA-Z0-9=\+\-\^\$\*\.\[\]\{\}\(\)\?"!@#%&\/\\,><':;\|_~`]*$/
    )
  ) {
    return {
      acceptable: true,
      err: '英大文字・英小文字・数字を各1字以上含めてください。'
    };
  }
  if (comparedPassword && password !== comparedPassword) {
    return {
      acceptable: true,
      err: '確認用パスワードが一致しません。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateAddUserPhoneNumber = (
  phoneNumber: string | undefined
): IValidationRes => {
  if (!phoneNumber?.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  } else {
    const replacedPhoneNumber = replaceHalfWidthDigit(phoneNumber);
    if (!replacedPhoneNumber.length) {
      return {
        acceptable: true,
        err: '半角数字で設定してください。'
      };
    }
    if (replacedPhoneNumber.length !== 11) {
      return {
        acceptable: true,
        err: '11ケタで設定してください。'
      };
    }
    if (!replacedPhoneNumber.match(/^(090|080|070|060|050)+[0-9]*$/)) {
      return {
        acceptable: true,
        err: 'SMSを受信できる番号にしてください。'
      };
    }
  }
  return {
    acceptable: true
  };
};

export const validateEditUserPhoneNumber = (
  phoneNumber: string | undefined
): IValidationRes => {
  if (phoneNumber?.length) {
    const replacedPhoneNumber = replaceHalfWidthDigit(phoneNumber);
    if (!replacedPhoneNumber.length) {
      return {
        acceptable: true,
        err: '半角数字で設定してください。'
      };
    }
    if (replacedPhoneNumber.length !== 11) {
      return {
        acceptable: true,
        err: '11ケタで設定してください。'
      };
    }
    if (!replacedPhoneNumber?.match(/^(090|080|070|060|050)+[0-9]*$/)) {
      return {
        acceptable: true,
        err: 'SMSを受信できる番号にしてください。'
      };
    }
  }
  return {
    acceptable: true
  };
};

export const validatePhoneNumber = (phoneNumber: string): IValidationRes => {
  if (!phoneNumber.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  const replacedPhoneNumber = replaceHalfWidthDigit(phoneNumber);
  if (!replacedPhoneNumber.length) {
    return {
      acceptable: true,
      err: '半角数字で設定してください。'
    };
  }
  if (replacedPhoneNumber.length > 11) {
    return {
      acceptable: true,
      err: '11ケタ以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validate6DigitCode = (code: string): IValidationRes => {
  if (!code.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  const replacedCode = replaceHalfWidthDigit(code);
  if (!replacedCode.length) {
    return {
      acceptable: true,
      err: '半角数字で入力してください。'
    };
  }
  if (replacedCode.length !== 6) {
    return {
      acceptable: true,
      err: 'コードは6ケタです。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateSearchString = (searchString: string): IValidationRes => {
  const trimmedSearchString = searchString.trim();
  if (trimmedSearchString.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateZipCode = (zipCode: string): IValidationRes => {
  if (!zipCode.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  const replacedZipCode = replaceHalfWidthDigit(zipCode);
  if (!replacedZipCode.length) {
    return {
      acceptable: true,
      err: '半角数字で入力ください。'
    };
  }
  if (replacedZipCode.length !== 7) {
    return {
      acceptable: true,
      err: '7ケタで設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateCity = (city: string): IValidationRes => {
  const trimmedCity = city.trim();
  if (!trimmedCity.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (trimmedCity.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateFollowingAddress = (
  followingAddress: string
): IValidationRes => {
  const trimmedFollowingAddress = followingAddress.trim();
  if (!trimmedFollowingAddress.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (trimmedFollowingAddress.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateEquipmentName = (
  equipmentName: string | null | undefined
): IValidationRes => {
  const trimmedEquipmentName = equipmentName?.trim();
  if (!trimmedEquipmentName?.match(/^[a-zA-Z0-9-]*$/)) {
    return {
      acceptable: true,
      err: '半角英数ハイフンで入力してください。'
    };
  }
  if (trimmedEquipmentName.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateName = (name: string | undefined): IValidationRes => {
  const trimmedName = name?.trim();
  if (!trimmedName?.length) {
    return {
      acceptable: true,
      err: '必須入力です。'
    };
  }
  if (trimmedName.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};

export const validateOther = (other: string | undefined): IValidationRes => {
  const trimmedOther = other?.trim();
  if (trimmedOther && trimmedOther?.length > 100) {
    return {
      acceptable: true,
      err: '100字以内で設定してください。'
    };
  }
  return {
    acceptable: true
  };
};
