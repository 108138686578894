import { AppThunk } from '.';
import {
  fetchProcesses,
  setProcesses,
  setProcessesError
} from '../actions/process';
import api from '@common/apis/process';
import { IProjectProcess } from '@common/types/process';

export const fetchAndSetProcesses = (
  projectId: number
): AppThunk => async dispatch => {
  dispatch(fetchProcesses());

  api
    .getProcessesList({
      projectId
    })
    .then(processes =>
      dispatch(setProcesses((processes || []) as IProjectProcess[]))
    )
    .catch(() => {
      dispatch(setProcesses([]));
      dispatch(setProcessesError());
    });
};
