import { fetchUser, setUser, clearUser } from '../actions/user';
import { clearProjects } from '../actions/project';
import { clearProcesses } from '../actions/process';
import { AppThunk } from '.';
import { IUserDetail } from '@common/types/user';
import Amplify from '@common/utils/amplify';
import userApi from '@common/apis/user';

async function getUser(response: any) {
  if (
    response?.signInUserSession &&
    response?.signInUserSession != null &&
    response?.username
  ) {
    const username = response.username;
    const user = await userApi.getUser(username || -1); // cognitoとAPIのユーザ認証が対応していないとここでエラーになるが、基本的にその心配はないはず
    return user;
  } else {
    return null;
  }
}

export const initialAuth = (
  email: string,
  password: string
): AppThunk => async dispatch => {
  dispatch(fetchUser());
  const response = await Amplify.signIn(email, password);
  const user = await getUser(response);
  dispatch(setUser(user as IUserDetail));
  if (user == null) return response;
  else return null;
};

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const mfa = (
  cognitoUser: any, //amplifyの返り値
  code: string
): AppThunk => async dispatch => {
  dispatch(fetchUser());
  const response = await Amplify.confirmSignIn(cognitoUser, code);
  const user = await getUser(response);
  dispatch(setUser(user as IUserDetail));
  if (user == null) return response;
  else return null;
};
/* eslint-enable */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const setNewPassword = (
  cognitoUser: any, //amplifyのAuth.signInの返り値
  password: string
): AppThunk => async dispatch => {
  dispatch(fetchUser());
  const response = await Amplify.setNewPassword(cognitoUser, password);
  const user = await getUser(response);
  dispatch(setUser(user as IUserDetail));
  if (user == null) return response;
  else return null;
};
/* eslint-enable */

export const logout = (): AppThunk => async dispatch => {
  dispatch(fetchUser());
  await Amplify.signOut();

  dispatch(clearUser());
  dispatch(clearProjects());
  dispatch(clearProcesses());

  window.location.href = '/login';
};

export const fetchCurrentUser = (): AppThunk => async dispatch => {
  dispatch(fetchUser());

  const cognitoUser = await Amplify.fetchCurrentUser();

  const username = cognitoUser?.username;
  if (username != null) {
    const user = await userApi.getUser(cognitoUser.username);
    dispatch(setUser(user as IUserDetail));
  } else {
    await Amplify.signOut();
    dispatch(setUser(null));
  }
};
