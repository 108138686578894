import React, { useState, useEffect } from 'react';
import { styleVariables } from '@common/components/_styleVariables';
import { connect } from 'react-redux';
import { ReduxState } from '../reducers';
import * as validator from '@common/utils/validator';
import * as errMsgs from '@common/utils/errMsgs';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

import { IProject, IProjectDetail } from '@common/types/project';
import projectApi from '@common/apis/project';

const StyledButton = withStyles({
  root: { padding: '5px 10px', minWidth: 20 }
})(Button);
const StyledTextField = styled(TextField)`
  .MuiFilledInput-input {
    padding: 12px;
  }
`;

interface Props {
  classes: any;
  setOpenProjectMenu(open: boolean): void;
  projectId: number;
  projectProcessId: number;
  setProjectAndProcess(
    projectId: number | null,
    projectProcessId: number | null
  ): void;
}
type PropsFromRedux = ReturnType<typeof mapStateToProps>;

export const ProjectsSearchBox: React.FC<Props & PropsFromRedux> = props => {
  const {
    classes,
    setOpenProjectMenu,
    projectId,
    setProjectAndProcess
  } = props;

  const [searchWord, setSearchWord] = useState<string>('');
  const [projectSearchError, setProjectSearchError] = useState<string | null>(
    null
  );
  const [projectsShow, setProjectsShow] = useState<
    Array<IProjectDetail | IProject>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const defaultMessage =
    props?.projects.projects.length > 99
      ? '最新の案件100件までが表示されています'
      : '案件数:' + props?.projects.projects.length + '件';
  const [message, setMessage] = useState<string>(defaultMessage);

  useEffect(() => {
    setMessage(defaultMessage);
    if (props.projects.isFetching) {
      setIsLoading(true);
    } else {
      setProjectsShow(props.projects.projects);
    }
  }, [props.projects]);

  useEffect(() => {
    if (searchWord === '') {
      setProjectsShow(props.projects.projects);
      setMessage(defaultMessage);
    }
  }, [searchWord]);

  const handleChangeSearchString = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const reqSearchString = event.target.value;
    const searchValidation = validator.validateSearchString(reqSearchString);
    if (!searchValidation.acceptable) {
      return;
    }
    if (searchValidation.err) {
      setProjectSearchError(searchValidation.err);
    } else {
      setProjectSearchError(null);
    }
    setSearchWord(reqSearchString);
  };

  // APIリクエストで絞り込み
  const searchProject = () => {
    if (isLoading || projectSearchError || searchWord === '') return;

    setIsLoading(true);
    setMessage('検索中...');
    const trimmedKeyword = searchWord.trim();
    projectApi
      .getProjectsList({
        managementFlag: false,
        keyword: trimmedKeyword
      })
      .then(data => {
        setProjectsShow(data.items);
        if (!data.items.length && !!trimmedKeyword) {
          const errMsg = errMsgs.getErrMsg('U1_FORM_S-01-01_01');
          const key = enqueueSnackbar(errMsg, {
            onClick: () => {
              closeSnackbar(key);
            },
            variant: 'error',
            preventDuplicate: true,
            autoHideDuration: 8000
          });
          setMessage('検索結果が0件です');
        } else {
          if (searchWord === '') setMessage(defaultMessage);
          else if (data.nextToken === '')
            setMessage('検索結果:' + data.items.length + '件');
          else setMessage('検索結果のうち、最初の100件のみ表示します');
        }
      })

      .catch(err => {
        let errMsg = errMsgs.getErrMsg('U2_NETWORK_S-01-01_11');
        if (err?.code == 'NotAuthorizedException') {
          errMsg = errMsgs.getErrMsg('U2_NETWORK_S-00-00_01');
        } else if (err?.response?.data) {
          errMsg = err.response.data;
        }
        const key = enqueueSnackbar(errMsg, {
          onClick: () => {
            closeSnackbar(key);
          },
          variant: 'error',
          preventDuplicate: true,
          autoHideDuration: 8000
        });
        setMessage('エラー');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpenProjectMenu(false);
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 64,
          left: 192,
          width: '558',
          overflowY: 'auto',
          zIndex: 99999,
          border: 'solid 1px #999999'
        }}
      >
        <Paper style={{ width: 558 }}>
          <form
            onSubmit={e => {
              e.preventDefault();
              searchProject();
            }}
          >
            <StyledTextField
              fullWidth
              className={classes.margin}
              type='search'
              placeholder='案件検索　案件名で検索できます'
              variant='filled'
              onChange={handleChangeSearchString}
              InputProps={{
                startAdornment: searchWord === '' && (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <StyledButton
                    color='primary'
                    onClick={searchProject}
                    disabled={
                      !!projectSearchError || searchWord === '' || isLoading
                    }
                  >
                    <SearchIcon />
                  </StyledButton>
                )
              }}
            />
          </form>

          <div>
            <span
              className='small'
              style={{ color: styleVariables.error.color }}
            >
              {projectSearchError}
            </span>
          </div>
          <div>{message}</div>
          <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
            {projectsShow.map(
              (project: IProject | IProjectDetail, index: number) => (
                <div key={index}>
                  <ListItem
                    onClick={() => {
                      setOpenProjectMenu(false);
                      setProjectAndProcess(project.projectId, null);
                    }}
                    selected={project.projectId === projectId}
                    button
                  >
                    <ListItemText style={{ wordBreak: 'break-all' }}>
                      {project.projectName}
                    </ListItemText>
                  </ListItem>
                  <Divider />
                </div>
              )
            )}
          </div>
        </Paper>
        <Divider orientation='vertical' flexItem />
      </div>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  projects: state.projects
});
export default connect(mapStateToProps)(ProjectsSearchBox);
