import { Auth } from 'aws-amplify';

class Amplify {
  /**
   * 指定されたパラメータでログインする。
   * @param email - メールアドレス
   * @param password - パスワード
   * @returns Promise<CognitoUser>
   */
  async signIn(email: string, password: string) {
    return await Auth.signIn(email, password);
  }

  /**
   * グローバルログアウトに失敗した場合、ローカルの認証情報(AmplifyがlocalStoageに持っている情報)を消す
   * @returns Promise<"SUCCESS">
   */
  async signOut() {
    return Auth.signOut({ global: true })
      .then(res => {
        return res;
      })
      .catch(() => {
        Auth.signOut({ global: false }).then(res => {
          return res;
        });
      });
  }

  /**
   * ログイン中のユーザを取得する
   * @returns Promise<CognitoUser>
   */
  async fetchCurrentUser() {
    return await Auth.currentUserInfo();
  }

  /**
   * 指定されたパラメータで二段階認証コードを検証する。
   * @param user loginAPIから返されたユーザ情報(公式ドキュメントでany型)
   * @param code - 二段階認証コード
   * @returns Promise<"SUCCESS">
   */
  async confirmSignIn(user: any, code: string) {
    return await Auth.confirmSignIn(user, code);
  }

  /**
   * 最新のidTokenを取得する
   * @returns Promise<CognitoIdToken>
   */
  async getCurrentIdToken() {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
  }

  /**
   * ログイン済みか確認する。
   * @returns Promise<CognitoUser>
   */
  async checkSignedIn() {
    return await Auth.currentAuthenticatedUser();
  }

  /**
   * 新しいパスワードを設定する
   * @returns Promise<CognitoUser>
   */
  async setNewPassword(user: any, password: string) {
    return await Auth.completeNewPassword(user, password);
  }

  /**
   * パスワード忘れの場合にリセットコードを要求する
   * @returns Promise<"SUCCESS">
   */
  async forgotPassword(mailAddress: string) {
    return await Auth.forgotPassword(mailAddress);
  }

  /**
   * パスワード忘れの場合に新しいパスワードを設定する
   * @returns Promise<"SUCCESS">
   */
  async forgotPasswordSubmit(
    mailAddress: string,
    code: string,
    newPassword: string
  ) {
    return await Auth.forgotPasswordSubmit(mailAddress, code, newPassword);
  }
}

export default new Amplify();
