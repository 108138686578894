const getApiBaseUrl = () => {
  switch (API_ENV) {
    case 'none':
      return 'http://localhost:8080';
    case 'development':
      return 'https://api.dev.d-site.org';
    case 'production':
      return 'https://api.system-dksite.com';
      // return 'https://api.combined-dsite.com'; // 結合環境
    default:
      return 'https://api.dev.d-site.org';
  }
};

const apiBaseUrl = getApiBaseUrl();

export { apiBaseUrl };
