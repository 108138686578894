/* eslint-disable */

const awsmobile =
  API_ENV === 'production'
    ? {
        aws_project_region: 'ap-northeast-1',
        aws_cognito_region: 'ap-northeast-1',
        aws_user_pools_id: 'ap-northeast-1_i4rcnYGBe',
        aws_user_pools_web_client_id: '2buus5cu6aeja1rnb3c97h3hii',
        // aws_user_pools_id: 'ap-northeast-1_WHG9mUqm6', // 結合環境
        // aws_user_pools_web_client_id: '5tl5keu5mk0o77ikr7e0scps6l',
        oauth: {}
      }
    : {
        aws_project_region: 'ap-northeast-1',
        aws_cognito_region: 'ap-northeast-1',
        aws_user_pools_id: 'ap-northeast-1_hl6yK1v72',
        aws_user_pools_web_client_id: '231s5mp1haslv017aollih01nj',
        oauth: {}
      };

export default awsmobile;
