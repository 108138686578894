import React, { lazy } from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
const Photos = lazy(() => import('../components/Photos'));
const Drawing = lazy(() => import('../components/Drawing'));
const EditOperations = lazy(() => import('../components/EditOperationsContainer'));
const OutputPhotoBook = lazy(() => import('../components/OutputPhotoBook'));
import ProjectsNotFound from '../ProjectsNotFound';
import PageNotFound from '../PageNotFound';

export const ProjectProcessRoutes: React.FC = () => {
  const match = useRouteMatch<{
    projectId: string;
    projectProcessId: string;
  }>();

  return (
    <Switch>
      <Route exact path={`${match.path}/photos`}>
        <Photos />
      </Route>
      <Route exact path={`${match.path}/drawing`}>
        <Drawing />
      </Route>
      <Route exact path={`${match.path}/operations`}>
        <EditOperations />
      </Route>
      <Route exact path={`${match.path}/output`}>
        <OutputPhotoBook />
      </Route>
      <Route exact path={`${match.path}/projectsNotFound`}>
        <ProjectsNotFound />
      </Route>
      <Route>
        <PageNotFound />
      </Route>
    </Switch>
  );
};
