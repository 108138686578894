import React, { useContext, createContext, Suspense } from 'react';
import { Action, bindActionCreators } from 'redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { ReduxState } from './reducers';
import { ThunkDispatch } from 'redux-thunk';
import { fetchCurrentUser } from './thunks/user';
import { initialState as initialUserState } from './reducers/user';

import {
  createMuiTheme,
  ThemeProvider,
  makeStyles
} from '@material-ui/core/styles';
import { styleVariables } from '@common/components/_styleVariables';

import { SnackbarProvider } from 'notistack';

import LoadingIndicator from '@common/components/LoadingIndicator';
import Amplify from 'aws-amplify';
import awsconfig from '@config/aws-exports';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import SystemError from './SystemError';
import { Routes } from './routes/index';

Amplify.configure(awsconfig);
const muiTheme = createMuiTheme({
  palette: {
    primary: {
      main: styleVariables.button.primary
    },
    secondary: {
      main: styleVariables.button.red
    }
  }
});
const useStyles = makeStyles({
  snackContainer: {
    position: 'fixed',
    top: 72,
    right: 8
  }
});

export const useAuth = () => useContext(AuthContext);
const AuthContext = createContext<PropsState & OwnProps>({
  user: initialUserState,
  fetchCurrentUser: () => {}
});

type PropsState = ReturnType<typeof mapStateToProps>;
type PropsDispatch = ReturnType<typeof mapDispatchToProps>;
type OwnProps = {
  fetchCurrentUser(): void;
};

const ProvideAuth: React.FC<PropsState & PropsDispatch & OwnProps> = ({
  children,
  ...rest
}) => <AuthContext.Provider value={rest}>{children}</AuthContext.Provider>;

const ErrorFallback: React.FC<FallbackProps> = () => {
  // TODO: 検知したい場面でthrow errorする
  return <SystemError />;
};

const App: React.FC<PropsState & PropsDispatch> = props => {
  const classes = useStyles();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ProvideAuth
        {...props}
        // 型定義変更のためOwnPropsにいれる
        fetchCurrentUser={props.fetchCurrentUser}
      >
        <Router>
          <ThemeProvider theme={muiTheme}>
            <SnackbarProvider
              maxSnack={8}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              classes={{
                containerRoot: classes.snackContainer
              }}
            >
              <Suspense fallback={<LoadingIndicator />}>
                <Routes />
              </Suspense>
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </ProvideAuth>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.user
});
const mapDispatchToProps = (
  dispatch: ThunkDispatch<ReduxState, void, Action>
) =>
  bindActionCreators(
    {
      fetchCurrentUser
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(App);
