import { IProjectProcess } from '@common/types/process';

export const FETCH_PROCESSES = 'FETCH_PROCESSES' as const;
export type FetchProcesses = {
  type: typeof FETCH_PROCESSES;
};

export const fetchProcesses = (): FetchProcesses => ({
  type: FETCH_PROCESSES
});

export const SET_PROCESSES_ERROR = 'SET_PROCESSES_ERROR' as const;
export type SetProcessesError = {
  type: typeof SET_PROCESSES_ERROR;
};
export const setProcessesError = (): SetProcessesError => ({
  type: SET_PROCESSES_ERROR
});

export const SET_PROCESSES = 'SET_PROCESSES' as const;
export type SetProcesses = {
  type: typeof SET_PROCESSES;
  payload: IProjectProcess[];
};

export const setProcesses = (processes: IProjectProcess[]): SetProcesses => ({
  type: SET_PROCESSES,
  payload: processes
});

export const CLEAR_PROCESSES = 'CLEAR_PROCESSES' as const;
export type ClearProcesses = {
  type: typeof CLEAR_PROCESSES;
};

export const clearProcesses = (): ClearProcesses => ({
  type: CLEAR_PROCESSES
});

export type ProcessesAction =
  | FetchProcesses
  | SetProcesses
  | ClearProcesses
  | SetProcessesError;
