export const styleVariables = {
  button: {
    primary: '#0097E0',
    primaryDisabled: 'rgba(0, 151, 224, 0.3)',
    secondary: '#FF3333',
    red: '#FF3333',
    redDisabled: 'rgba(255, 51, 51, 0.3)'
  },
  header: {
    height: '80px'
  },
  sidebar: {
    color: '#eaebec',
    width: '320px'
  },
  mustItem: {
    color: '#FF3333'
  },
  error: {
    color: '#FF3333'
  }
};
