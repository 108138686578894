import { Reducer } from 'redux';
import { IProject, IProjectDetail } from '@common/types/project';
import {
  FETCH_PROJECTS,
  ADD_PROJECT,
  SET_PROJECTS,
  CLEAR_PROJECTS,
  SET_PROJECTS_ERROR,
  ProjectsAction
} from '../actions/project';

export type ProjectsState = {
  isFetching: boolean;
  isFetched: boolean;
  error: boolean;
  projects: Array<IProject | IProjectDetail>;
};

export const initialState: ProjectsState = {
  isFetching: false,
  isFetched: false,
  error: false,
  projects: []
};

const projectReducer: Reducer<ProjectsState, ProjectsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_PROJECTS: {
      return {
        ...state,
        isFetching: true,
        error: false
      };
    }

    case SET_PROJECTS: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: false,
        projects: action.payload
      };
    }
    case ADD_PROJECT: {
      if (action.payload == null) {
        return {
          ...state,
          isFetching: false,
          isFetched: true,
          error: false
        };
      }
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: false,
        projects: [...state.projects, action.payload]
      };
    }

    case SET_PROJECTS_ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        error: true,
        projects: []
      };
    }

    case CLEAR_PROJECTS: {
      return initialState;
    }

    default:
      return state;
  }
};

export default projectReducer;
