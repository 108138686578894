import React, { useState, useEffect } from 'react';
import {
  RouteComponentProps,
  useRouteMatch,
  useLocation,
  useHistory,
  useParams
} from 'react-router-dom';
import { generatePath } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ReduxState } from '../reducers';
import { logout, fetchCurrentUser } from '../thunks/user';
import { fetchAndSetProjects, fetchAddProject } from '../thunks/project';
import { fetchAndSetProcesses } from '../thunks/process';
import ProcessApi from '@common/apis/process';

import { Theme, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import { useSnackbar } from 'notistack';

import ProjectProcessHeader from './ProjectProcessHeader';
import ProjectsSearchBox from './ProjectsSearchBox';
import { ProjectProcessSideBar } from './ProjectProcessSideBar';
import { ProjectProcessRoutes } from '../routes/ProjectProcess';
import LoadingIndicator from '@common/components/LoadingIndicator';
import * as errMsgs from '@common/utils/errMsgs';

const drawerWidth = 192;

const styles = (theme: Theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    backgroundColor: '#1D1E20',
    zIndex: theme.zIndex.drawer + 1
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingRight: 0
  },
  content: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: 'calc(100vh - 64px)',
    position: 'relative',
    top: '64px'
  }
});

type OwnProps = {
  classes: any;
} & RouteComponentProps<{ projectId: string; projectProcessId: string }>;
type PropsFromRedux = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;
const PageTemplate: React.FC<OwnProps & PropsFromRedux> = props => {
  const match = useRouteMatch<{
    projectId: string;
    projectProcessId: string;
  }>();
  const location = useLocation();
  const history = useHistory();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const params = useParams<{
    projectId: string;
    projectProcessId: string;
  }>();
  const paramProjectId = params.projectId;
  const paramProjectProcessId = params.projectProcessId;

  const [isOpenUserMenu, setOpenUserMenu] = useState<boolean>(false);
  const [isOpenProcessMenu, setIsOpenProcessMenu] = React.useState<boolean>(
    false
  );
  const [openProject, setOpenProject] = React.useState<boolean>(false);

  const currentParamProjectId = Number(paramProjectId);
  const currentParamProject = props.projects.projects.find(
    project => project.projectId === currentParamProjectId
  );
  const currentParamProjectProcessId = Number(paramProjectProcessId);
  const currentParamProcess = props.processes.processes.find(
    process => process.projectProcessId === currentParamProjectProcessId
  );

  const [disabledMenu, setDisabledMenu] = useState<boolean>(false);

  useEffect(() => {
    if (props.user.user) {
      props.fetchAndSetProjects();
    }
  }, [props.user.user]);

  // URLがセットされてから大工程を取ってくる
  // セットする前にも取ってきてるので現状大工程取得を2回呼んでしまっている
  useEffect(() => {
    if (props?.user?.user == null || isNaN(Number(match?.params?.projectId)))
      return;

    if (Number(match?.params?.projectId) > 0) {
      props.fetchAndSetProcesses(Number(match?.params?.projectId));
    }
  }, [match?.params?.projectId, props.user.user]);

  //URLの案件・大工程IDが変わったときにReduxに案件・大工程をセットする
  useEffect(() => {
    if (!props.user.isFetched || !props.projects?.isFetched) {
      return;
    }
    if (!props.projects?.projects.length) {
      history.push(`${match.url}/projectsNotFound`);
      return;
    }

    const projectIdFromURL = isNaN(Number(match?.params?.projectId))
      ? null
      : Number(match?.params?.projectId);

    const processIdFromURL = isNaN(Number(match?.params?.projectProcessId))
      ? null
      : Number(match?.params?.projectProcessId);

    setProjectAndProcess(projectIdFromURL, processIdFromURL);
  }, [
    props.projects.isFetched,
    match?.params?.projectId,
    match?.params?.projectProcessId,
    props.user.user
  ]);

  // 案件ID・案件大工程IDの存在を検証して遷移
  async function setProjectAndProcess(
    projectId: number | null,
    projectProcessId: number | null
  ) {
    const nextProjectId = await checkProjectId(projectId);

    const nextProcesses = await ProcessApi.getProcessesList({
      projectId: nextProjectId
    }).catch(() => {
      const key = enqueueSnackbar(errMsgs.getErrMsg('U2_NETWORK_S-01-03_11'), {
        onClick: () => {
          closeSnackbar(key);
        },
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 8000
      });
      return [];
    });
    if (nextProcesses.length == 0) return;

    const selectedProcessExists = nextProcesses.some(
      process => process.projectProcessId === projectProcessId
    );
    const nextProcessId =
      projectProcessId != null && selectedProcessExists
        ? projectProcessId
        : nextProcesses[0].projectProcessId;

    // IDが同一のときは遷移しない
    if (
      nextProjectId == Number(match?.params?.projectId) &&
      nextProcessId == Number(match?.params?.projectProcessId)
    )
      return;

    const nextPath = generatePath(match.path, {
      projectId: nextProjectId,
      projectProcessId: nextProcessId
    });
    const urlSuffix = location.pathname.replace(
      /\/projects\/.+\/processes\/.+\//g,
      ''
    );
    props.history.push(`${nextPath}/${urlSuffix}${location.search}`);
  }

  async function checkProjectId(requestProjectId: number | null) {
    if (requestProjectId == null) {
      return props.projects.projects[0].projectId;
    }
    const selectedProjectExists = props.projects?.projects?.some(
      project => project.projectId === requestProjectId
    );
    if (selectedProjectExists) {
      return requestProjectId;
    } else {
      return await props
        .fetchAddProject(requestProjectId)
        .then(() => {
          return requestProjectId;
        })
        .catch(() => {
          const key = enqueueSnackbar(
            errMsgs.getErrMsg('U2_NETWORK_S-01-01_12'),
            {
              onClick: () => {
                closeSnackbar(key);
              },
              variant: 'error',
              preventDuplicate: true,
              autoHideDuration: 8000
            }
          );
          return props.projects.projects[0].projectId;
        });
    }
  }

  // thunksのエラー
  useEffect(() => {
    if (props.projects.error) {
      const key = enqueueSnackbar(errMsgs.getErrMsg('U2_NETWORK_S-01-01_10'), {
        onClick: () => {
          closeSnackbar(key);
        },
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 8000
      });
    }

    if (props.processes.error) {
      const key = enqueueSnackbar(errMsgs.getErrMsg('U2_NETWORK_S-01-03_11'), {
        onClick: () => {
          closeSnackbar(key);
        },
        variant: 'error',
        preventDuplicate: true,
        autoHideDuration: 8000
      });
    }
  }, [props.processes, props.projects]);

  useEffect(() => {
    if (props.projects.isFetching) return;

    if (!props.projects.projects?.length) {
      setDisabledMenu(true);
    } else {
      setDisabledMenu(false);
    }
  }, [props.projects]);

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setIsOpenProcessMenu(false);
    }
  };

  const { classes } = props;
  const isSelected = (path: string) => !!useRouteMatch(match?.url + path);

  if (!props.user.user) {
    return <LoadingIndicator />;
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ProjectProcessHeader
        classes={classes}
        setOpenUserMenu={setOpenUserMenu}
        setOpenProjectMenu={setOpenProject}
        setOpenProcessMenu={setIsOpenProcessMenu}
        openUserMenu={isOpenUserMenu}
        openProcessMenu={isOpenProcessMenu}
        openProjectMenu={openProject}
        currentProject={currentParamProject}
        currentProcess={currentParamProcess}
        projectId={Number(props.match.params.projectId)}
        isDisabled={disabledMenu}
        setProjectAndProcess={setProjectAndProcess}
        handleListKeyDown={handleListKeyDown}
      />
      <ProjectProcessSideBar
        classes={classes}
        baseUrl={match?.url}
        isSelectedPath={isSelected}
        isDisabled={disabledMenu}
      />
      {openProject && (
        <ProjectsSearchBox
          classes={classes}
          setOpenProjectMenu={setOpenProject}
          projectId={currentParamProjectId}
          projectProcessId={currentParamProjectProcessId}
          setProjectAndProcess={setProjectAndProcess}
        />
      )}

      <main className={classes.content}>
        <div
          style={{
            height: '100%'
          }}
        >
          <ProjectProcessRoutes />
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => ({
  user: state.user,
  projects: state.projects,
  processes: state.processes
});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      logout,
      fetchCurrentUser,
      fetchAndSetProjects,
      fetchAddProject,
      fetchAndSetProcesses
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(PageTemplate));
