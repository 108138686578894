import { Reducer } from 'redux';
import { IUserDetail } from '@common/types/user';
import { FETCH_USER, SET_USER, CLEAR_USER, UserAction } from '../actions/user';

export type UserState = {
  isFetching: boolean;
  isFetched: boolean;
  user: IUserDetail | null;
};

export const initialState: UserState = {
  isFetching: false,
  isFetched: false,
  user: null
};

const userReducer: Reducer<UserState, UserAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FETCH_USER: {
      return {
        ...state,
        isFetching: true,
        isFetched: false
      };
    }

    case SET_USER: {
      return {
        ...state,
        isFetching: false,
        isFetched: true,
        user: action.payload
      };
    }

    case CLEAR_USER: {
      return initialState;
    }

    default:
      return state;
  }
};

export default userReducer;
